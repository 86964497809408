(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/dialog/assets/javascripts/facade.js') >= 0) return;  svs.modules.push('/components/components/dialog/assets/javascripts/facade.js');
"use strict";


svs = svs || {};
svs.components = svs.components || {};
svs.components.dialog = svs.ui.dialog || {};
svs.components.dialog.area = svs.ui.dialog.area || {};
svs.components.dialog.areas = svs.ui.dialog.areas || {};
svs.components.dialog.type = svs.ui.dialog.type || {};
svs.components.dialog.keyEvent = svs.ui.dialog.keyEvent || {};
svs.components.dialog.message = svs.ui.dialog.message || {};
svs.components.dialog.message.align = svs.components.dialog.message.align || {};
svs.components.dialog.priority = svs.ui.dialog.priority || {};
svs.components.dialog.branding = svs.ui.dialog.branding || {};
svs.components.dialog.autoClose = svs.ui.dialog.autoClose || {};
svs.components.dialog.handshakeTimeout = svs.components.dialog.handshakeTimeout || {};
svs.components.dialog.button = svs.ui.dialog.button || {};
svs.components.dialog.type.DEFAULT = svs.ui.dialog.type.DEFAULT;
svs.components.dialog.type.CONFIRM = svs.ui.dialog.type.CONFIRM;
svs.components.dialog.type.ERROR = svs.ui.dialog.type.ERROR;
svs.components.dialog.branding.TUR = svs.ui.dialog.branding.TUR;
svs.components.dialog.branding.SPORT = svs.ui.dialog.branding.SPORT;
svs.components.dialog.branding.NEUTRAL = svs.ui.dialog.branding.NEUTRAL;
svs.components.dialog.branding.ERROR = svs.ui.dialog.branding.ERROR;
svs.components.dialog.autoClose.SHORT = svs.ui.dialog.autoClose.SHORT;
svs.components.dialog.autoClose.MEDIUM = svs.ui.dialog.autoClose.MEDIUM;
svs.components.dialog.autoClose.LONG = svs.ui.dialog.autoClose.LONG;
svs.components.dialog.autoClose.VERY_LONG = svs.ui.dialog.autoClose.VERY_LONG;
svs.components.dialog.autoClose.DEFAULT = svs.ui.dialog.autoClose.DEFAULT;
svs.components.dialog.handshakeTimeout.DEFAULT = svs.ui.dialog.handshakeTimeout.DEFAULT;
svs.components.dialog.message.TEXT = svs.ui.dialog.message.TEXT;
svs.components.dialog.message.HREF = svs.ui.dialog.message.HREF;
svs.components.dialog.message.CHECKBOX = svs.ui.dialog.message.CHECKBOX;
svs.components.dialog.message.align.LEFT = svs.ui.dialog.message.align.LEFT;
svs.components.dialog.message.align.CENTER = svs.ui.dialog.message.align.CENTER;
svs.components.dialog.message.align.RIGHT = svs.ui.dialog.message.align.RIGHT;
svs.components.dialog.button.PRIMARY = svs.ui.dialog.button.PRIMARY;
svs.components.dialog.button.SECONDARY = svs.ui.dialog.button.SECONDARY;
svs.components.dialog.api = svs.ui.dialog.api;

 })(window);